import React, { useContext } from 'react';
import { graphql, Link as GatsbyLink, PageRendererProps } from 'gatsby';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Paper, Grid } from '@material-ui/core';
import { Card } from '../components/Card/Card';
import { CardHeader } from '../components/Card/CardHeader';
import Img from 'gatsby-image';
import { CardBody } from '../components/Card/CardBody';
import classNames from 'classnames';
import { categoryStyle } from '../assets/jss/pages/categoryStyle';
import { DefaultHeader } from '../components/Header/Header';
import { AppearingAtNode } from '../interfaces/contentful';
import { Parallax } from '../components/Parallax/Parallax';
import { CartDispatchContext } from '../store/StateProvider';
import { AddToCartButton } from '../components/Button/AddToCartButton';
import { FormattedMessage } from 'react-intl';
import { Price } from '../components/Price/Price';
import { Breadcrumbs } from '../components/Breadcrumbs/Breadcrumbs';
import { Helmet } from 'react-helmet';

interface CategoryPageProps extends PageRendererProps {
  data: {
    category: {
      contentful_id: string;
      title: string;
      slug: string;
    };
    productsInCategory: {
      edges: [
        {
          node: AppearingAtNode;
        },
      ];
    };
  };
}

export const categoryQuery = graphql`
  query($slug: String!, $locale: String!) {
    category: contentfulCategory(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      contentful_id
      title
      slug
    }
    productsInCategory: allContentfulAppearingAt(sort: { fields: [year, month], order: [DESC, DESC] }) {
      edges {
        node {
          month
          year
          desc
          product {
            contentful_id
            slug
            title
            description {
              description
            }
            price
            discountedPrice
            node_locale
            featured
            category {
              contentful_id
              title
              slug
            }
            cover {
              fluid(maxWidth: 250, quality: 80) {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles(categoryStyle);

// TODO: here we display by appearing at, for each month one big container, inside, the tiles go in rows, similar like in main page for the latest, so 6 per row
const CategoryPageTemplate: React.FC<CategoryPageProps> = ({ data }) => {
  const { category, productsInCategory } = data;

  const dispatch = useContext(CartDispatchContext);
  const classes = useStyles();
  const theme = useTheme();

  // TODO: maybe one day extract the categories better, forming a proper object first. Or maybe GraphQL will allow for better nested filtering or flatteting arrays...
  const sections = productsInCategory.edges
    .filter(({ node }) => node.product)
    .map(({ node }) => {
      const filteredProducts = node.product.filter((product) =>
        product.category.map((cat) => cat.contentful_id).includes(category.contentful_id),
      );

      if (filteredProducts.length > 0) {
        return (
          <Paper
            key={`${node.month}-${node.year}`}
            style={{ padding: theme.spacing(2), marginBottom: theme.spacing(2) }}
          >
            {/*<a style={{ textDecoration: 'none' }} href={`#batch${node.year}-${node.month}`}>*/}
            <h2 id={`batch${node.year}-${node.month}`}>{node.desc}</h2>
            {/*</a>*/}
            <Grid container justify="flex-start" spacing={2}>
              {filteredProducts.map((product) => {
                return (
                  <Grid item key={product.contentful_id} md={3} sm={4} xs={12}>
                    <Card product style={{ minHeight: '290px' }}>
                      <CardHeader image plain>
                        <GatsbyLink
                          to={`/products/${product.slug}`}
                          state={{
                            categoryUrl: `/categories/${category.slug}`,
                            categoryTitle: `${category.title}`,
                          }}
                        >
                          <Img sizes={{ ...product.cover.fluid, aspectRatio: 1 / 1 }} />
                        </GatsbyLink>
                      </CardHeader>
                      <CardBody className={classes.textCenter} plain>
                        <h4 className={classes.cardTitle}>{product.title}</h4>
                        <Price product={product} />
                        <br />
                        <AddToCartButton product={product} quantity={1} size="sm" onlyIcon={true} />
                      </CardBody>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Paper>
        );
      }
    });

  return (
    <div className={classes.categoryPage}>
      <Helmet>
        <title>{category.title}</title>
      </Helmet>
      <DefaultHeader />

      <Parallax image={require('../assets/img/bg9.jpg')} className={classes.parallax}>
        <div className={classes.container}>
          <Breadcrumbs>
            <Typography color="secondary">{category.title}</Typography>
          </Breadcrumbs>
        </div>
      </Parallax>

      <div className={classNames(classes.section)}>
        <div className={classes.container}>
          <div className={classNames(classes.main, classes.mainRaised)}>
            <Typography variant="h5" component="h2" gutterBottom>
              {category.title}
            </Typography>

            {sections.filter(Boolean).length <= 0 && (
              <Paper style={{ padding: theme.spacing(2) }}>
                <FormattedMessage
                  id="category.noProductsInThisCategory"
                  defaultMessage="No products in this category"
                />
              </Paper>
            )}
            {sections.filter(Boolean).length > 0 && sections}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryPageTemplate;
